import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { Container, Row, Col, Hidden } from "react-grid-system";
////------------------Images----------------////
import Logo from "../../assets/images/logo-mib.gif";
import LogoCan from "../../assets/images/logo-cadlenly.svg";
////---------Styles--------////
import "./styles.scss";
import useLang from "../commons/useLang";

const navLinks = [
  "/#productos",
  "/#beneficios",
  "/#calidad",
  "/#testimonios",
  "/contacto",
];
const Header = (props) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulCommons {
        nodes {
          demoButton
        }
      }
      allContentfulNav {
        nodes {
          navList
        }
      }
    }
  `);

  const [currentLang, setCurrentLang] = useLang();
  const [selectedLang, setSelectedLang] = useState(0);
  const navList = data.allContentfulNav.nodes[currentLang].navList;
  const nameButton = data.allContentfulCommons.nodes[currentLang].demoButton;
  const closeMenu = function () {
    setIsActive(false);
  };
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setSelectedLang(currentLang);
  }, []);

  return (
    <>
      <Container className="nav-container" id="nav">
        <Row
          className={`nav-container--row divisor ${
            isActive ? "is-active" : ""
          }`}
        >
          <Col xs={9} sm={"content"}>
            <div className="logo-nav">
              <Link to="/#nav">
                <img
                  src={`${Logo}?v=${Math.random().toString(36)}`}
                  alt="Mobile-I-Business"
                />
              </Link>
            </div>
          </Col>
          <Hidden lg xl xxl>
            <Col xs={3}>
              <div className="burger-menu">
                <button
                  onClick={() => setIsActive(!isActive)}
                  className={`hamburger hamburger--spring ${
                    isActive ? "is-active" : ""
                  }`}
                >
                  <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                  </span>
                </button>
              </div>
            </Col>
          </Hidden>
          <Hidden xs sm md>
            <Col>
              <div>
                <ul className="linkNav">
                  {navList.map((texto, index) => {
                    return (
                      <a
                        href={navLinks[index]}
                        className={
                          props.selectedOption === navLinks[index] ? "blue" : ""
                        }
                      >
                        <li>{texto}</li>
                      </a>
                    );
                  })}
                </ul>
              </div>
            </Col>
          </Hidden>
          <Hidden xs sm md>
            <Col>
              <div className="language">
                <p
                  onClick={() => setCurrentLang("es")}
                  className={selectedLang === 0 ? "lang-bold" : ""}
                >
                  ESP
                </p>
                <p
                  onClick={() => setCurrentLang("en")}
                  className={selectedLang === 1 ? "lang-bold" : ""}
                >
                  ENG
                </p>
              </div>
            </Col>
          </Hidden>
        </Row>
        <Hidden lg xl xxl>
          <div className={`linksBurger ${isActive ? "is-active" : ""}`}>
            <ul className="linkBurger">
              {navList.map((texto, index) => {
                return (
                  <a href={navLinks[index]} onClick={closeMenu}>
                    <li>{texto}</li>
                  </a>
                );
              })}
              <a
                href="https://calendly.com/gciacomercial/alejandro"
                target="_blank"
              >
                <li>
                  <img src={LogoCan} className="calendly" />
                  {nameButton}
                </li>
              </a>
              <div className="language">
                <p
                  onClick={() => setCurrentLang("en")}
                  className={currentLang === 1 ? "lang-bold" : ""}
                >
                  ENG
                </p>
                <p
                  onClick={() => setCurrentLang("es")}
                  className={currentLang === 0 ? "lang-bold" : ""}
                >
                  ESP
                </p>
              </div>
            </ul>
          </div>
        </Hidden>
      </Container>
    </>
  );
};

export default Header;
