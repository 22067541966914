import { useState } from "react";

function useLang() {
  const [currentLang, setCurrentLang] = useState(() => {
    try {
      const item = window.localStorage.getItem("lang");
      return item ? JSON.parse(item) : 0;
    } catch (error) {
      console.error(error);
      return 0;
    }
  });

  const setLang = (value) => {
    try {
      // texts nodes come in an array
      // -- [0] == "es"
      // -- [1] == "en"
      const valueToStore = value === "es" ? 0 : 1;
      setCurrentLang(valueToStore);
      window.localStorage.setItem("lang", JSON.stringify(valueToStore));
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return [currentLang, setLang];
}

export default useLang;
