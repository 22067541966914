import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col, Hidden } from "react-grid-system";
/////--------Components------////
import DemoButton from "../commons/DemoButton";
import useLang from "../commons/useLang";
////---------Image----------////
import Mib from "../../assets/images/marca-mib.svg";
import Lknd from "../../assets/images/icono-linkedin.svg";
import Whatsapp from "../../assets/images/icono-whatsapp.svg";
////---------Styles--------////
import "./styles.scss";
const Footer = ({ isContactPage }) => {
  const data = useStaticQuery(graphql`
    query getCommonsTexts {
      allContentfulCommons {
        nodes {
          copyright
          webTitle
          address
        }
      }
      allContentfulNav {
        nodes {
          navList
        }
      }
    }
  `);
  const [currentLang] = useLang();
  const commonsTexts = data.allContentfulCommons.nodes[currentLang];
  const navTexts = data.allContentfulNav.nodes[currentLang];

  return (
    <>
      <section className="footer-container">
        <Container>
          <Row>
            <Col lg={2}>
              <a href="/#nav">
                <img src={Mib} />
              </a>
            </Col>
            <Col lg={6}>
              <ul className="nav-footer">
                <a href="/#productos">
                  <li>{navTexts.navList[0]}</li>
                </a>
                <a href="/#beneficios">
                  <li>{navTexts.navList[1]}</li>
                </a>
                <a href="/#calidad">
                  <li>{navTexts.navList[2]}</li>
                </a>
                <a href="/#testimonios">
                  <li>{navTexts.navList[3]}</li>
                </a>
                <div className="redes">
                  <a
                    href="https://www.linkedin.com/company/mobile-i-business/"
                    target="_blank"
                  >
                    <li>
                      <img src={Lknd} />
                    </li>
                  </a>
                  <a
                    className="wht"
                    href="https://bit.ly/3ANBXgw"
                    target="_blank"
                  >
                    <li>
                      <img src={Whatsapp} />
                    </li>
                  </a>
                </div>
              </ul>
            </Col>
            <Hidden xs sm md>
              {!isContactPage && (
                <Col lg={3}>
                  <DemoButton styleBoton={"boton-footer"} />
                </Col>
              )}
            </Hidden>
          </Row>
          <Row>
            <Col>
              <p className="address">{commonsTexts.address}</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="copyright">
        <Container>
          <Row>
            <Col>
              <p>{commonsTexts.copyright}</p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Footer;
