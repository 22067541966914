import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import useLang from "../useLang";
////-------------Styles---------------////
import "./styles.scss";

const DemoButton = (props) => {
  const data = useStaticQuery(graphql`
    query getDemoText {
      allContentfulCommons {
        nodes {
          demoButton
        }
      }
    }
  `);
  const [currentLang] = useLang();
  const demoText = data.allContentfulCommons.nodes[currentLang].demoButton;

  return (
    <Link to="/contacto">
      <button className={`demoButton ${props.styleBoton}`}>{demoText}</button>
    </Link>
  );
};

export default DemoButton;
